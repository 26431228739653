<template>
  <div class="d-flex align-items-center min-vh-100 login-container">
    <b-container>
      <b-row class="justify-content-center">
        <b-col md="9" lg="6" class="login">
          <b-card-group>
            <b-card class="p-4 shadow-lg">
              <b-card-body class="login-box">
                <b-form>
                  <div class="text-center">
                    <!-- <img src="/logo.png" alt /> -->
                    <div
                      class="imgLogo"
                      v-bind:style="{
                        'background-image': 'url(' + imgLogo + ')',
                      }"
                    ></div>
                    <hr class="my-4" />
                  </div>
                  <InputText
                    class="my-3 login-input"
                    v-model="form.email"
                    textFloat="Email"
                    placeholder="อีเมล"
                    type="email"
                    name="email"
                    isRequired
                    :isValidate="$v.form.email.$error"
                    :v="$v.form.email"
                    @onKeyup="submitFormOnInput"
                  />
                  <InputText
                    class="my-3 login-input"
                    v-model="form.password"
                    textFloat="Password"
                    placeholder="รหัสผ่าน"
                    type="password"
                    name="password"
                    isRequired
                    :isValidate="$v.form.password.$error"
                    :v="$v.form.password"
                    @onKeyup="submitFormOnInput"
                    isShowPassword
                  />
                  <b-row v-if="error != ''" class="m-2 text-center">
                    <span class="text-danger w-100">
                      {{ error }}
                      <br />
                      {{ " " }}
                    </span>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-button
                        type="button"
                        class="px-4 login-btn"
                        @click="checkForm"
                        :disabled="isDisable"
                        >เข้าสู่ระบบ
                        <b-spinner
                          class="align-middle w-1rem"
                          v-if="isLogin"
                        ></b-spinner
                      ></b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>

    <ModalComingSoon ref="modalComingSoon" />
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import ModalComingSoon from "@/components/modal/alert/ModalComingSoon";
import VueCookies from "vue-cookies";

export default {
  name: "Login",
  components: {
    InputText,
    ModalComingSoon,
  },
  data() {
    return {
      imgLogo: "",
      error: "",
      form: {
        email: "",
        password: "",
      },
      isLoading: false,
      isDisable: false,
      isLogin: false,
      passwordType: "",
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: { required },
      },
    };
  },
  mounted: async function () {
    await this.getLogo();
  },
  created() {
    // setTimeout(() => {
    //   this.$nextTick(() => {
    //     this.$refs.modalComingSoon.show();
    //   });
    // }, 500);
  },
  methods: {
    getLogo: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/setting/getLogo`,
        null,
        this.$headers,
        null
      );
      this.imgLogo = resData.detail;
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.isDisable = true;
      this.isLogin = true;
      this.error = "";
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/login`,
        null,
        null,
        this.form
      );
      this.isLoading = false;
      this.isDisable = false;
      this.isLogin = false;
      if (data.result == 1) {
        await this.$cookies.set(
          "admin-token",
          data.detail.token,
          60 * 60 * 24 * 30
        );
        await this.$cookies.set(
          "username",
          data.detail.userDetail.firstname +
            " " +
            data.detail.userDetail.lastname,
          60 * 60 * 24 * 30
        );
        window.location.href = "/";
      } else {
        this.error = data.message;
      }
    },
    submitFormOnInput: function (e) {
      if (e.keyCode === 13) {
        this.checkForm();
      }
    },
    handleShowHidePassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
};
</script>

<style scoped>
.w-1rem {
  width: 1rem;
  height: 1rem;
}

.button-eye {
  position: absolute;
  right: 10px;
  top: 0.8rem;
  z-index: 6;
}
</style>